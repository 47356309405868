header {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/2ea69fefc9df69f54f50f44144d88892.gif");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  padding-top: 10%;
  font-size: medium;
  position: relative;
}

.bio {
  letter-spacing: 0.4rem;
}

.header__container h1 {
  font-weight: 900;
  font-size: 3rem;
  letter-spacing: 0.139rem;
}

/*============ CTA ==============*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*============ SOCIALS ==============*/

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--color-primary);
}

/*============ SCROLL DOWN ==============*/

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ======================== MEDIA QUERIES (MEDIUM DEVICES) =========================*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}
/* ======================== MEDIA QUERIES (SMALL DEVICES) =========================*/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
